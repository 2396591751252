export const HOST = process.env.REACT_APP_API_URL;
export const WAPI = process.env.REACT_APP_WAPI_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,
    LOGINWEB: `${HOST}${VERSION}auth/loginWeb/`,
    WAPI_ISLOGIN: `${WAPI}islogin/`,

    // PARAMETER
    SEARCHPARAMS: `${HOST}${VERSION}admin/parameter/searchParams/`,

    SEARCHTYPECATEGORY: `${HOST}${VERSION}admin/parameter/searchtypecategory/`,
    CREATEPARAMETER: `${HOST}${VERSION}admin/parameter/create/`,
    DELETEPARAMETER: `${HOST}${VERSION}admin/parameter/deleteparameter/`,

    // USERS
    LISTUSERS: `${HOST}${VERSION}admin/users/listUsers/`,
    LISTSELECTDPTO: `${HOST}${VERSION}admin/users/listSelectDpto/`,
    LISTSELECTCITIE: `${HOST}${VERSION}admin/users/listSelectCitie/`,
    CREATEUSER: `${HOST}${VERSION}admin/users/createOnlyUser/`,
    UPDATEUSER: `${HOST}${VERSION}admin/users/updateOnlyUser/`,
    TOTALCLIENT: `${HOST}${VERSION}admin/users/totalClient/`,

    // CLIENTS
    LISTCLIENTS: `${HOST}${VERSION}admin/users/listClients/`,
    INFOCLIENT: `${HOST}${VERSION}admin/users/infoClient/`,
    CREATECLIENT: `${HOST}${VERSION}admin/users/createClient/`,
    UPDATECLIENT: `${HOST}${VERSION}admin/users/updateClient/`,
    UPLOADDOCCLIENT: `${HOST}${VERSION}admin/users/uploadDocClient/`,
    UPDATEDOCCLIENT: `${HOST}${VERSION}admin/users/updateDocClient/`,
    LISTDOCSCLIENT: `${HOST}${VERSION}admin/users/listDocClient/`,
    SEARCHUSER: `${HOST}${VERSION}web/searchWebClient/`,
    CREATEWEBCLIENT: `${HOST}${VERSION}web/createWebClient/`,
    CREATEWEBCLIENTALONE: `${HOST}${VERSION}web/createWebClientAlone/`,
    CREATECOMPLEMENTINFO: `${HOST}${VERSION}admin/users/createComplementInfo/`,
    UPLOADCLIENTS: `${HOST}${VERSION}admin/imports/uploadClients/`,
    SEARCHDETAIL: `${HOST}${VERSION}admin/users/searchDetail/`,
    UPDATEPASS: `${HOST}${VERSION}admin/users/updatePass/`,
    RECOVERYPASS: `${HOST}${VERSION}web/recovery/`,
    VALIDDOCCLIENT: `${HOST}${VERSION}admin/users/validDocClient/`,
    UPLOADPHOTO: `${HOST}${VERSION}admin/users/updatePhoto/`,

    // AGREEMENTS
    LISTAGREE: `${HOST}${VERSION}admin/agreements/listAgreements/`,
    LISTSELECTAGREE: `${HOST}${VERSION}admin/agreements/listSelectAgreements/`,
    LISTSELECTAGREEWEB: `${HOST}${VERSION}web/parameter/listSelectAgreements/`,
    SEARCHAGREE: `${HOST}${VERSION}admin/agreements/searchAgreement/`,
    CREATEAGREE: `${HOST}${VERSION}admin/agreements/createAgreement/`,
    UPDATEAGREE: `${HOST}${VERSION}admin/agreements/updateAgreement/`,

    // PROFILES
    LISTPROFILE: `${HOST}${VERSION}admin/profile/listProfiles/`,
    CREATEPROFILE: `${HOST}${VERSION}admin/profile/createProfile/`,
    UPDATEPROFILE: `${HOST}${VERSION}admin/profile/updateProfile/`,
    LISTSELECTPROFILE: `${HOST}${VERSION}admin/profile/listSelectProfiles/`,

    //SIMULATION
    CREATEPARAMSCREDIT: `${HOST}${VERSION}admin/simulation/createParamsCredit/`,
    UPDATEPARAMSCREDIT: `${HOST}${VERSION}admin/simulation/updateParamsCredit/`,
    CREATESIMU: `${HOST}${VERSION}admin/simulation/createSimulate/`,
    UPDATESIMU: `${HOST}${VERSION}admin/simulation/updateSimulate/`,
    LISTCONFSIMU: `${HOST}${VERSION}admin/simulation/configParamsCredit/`,
    LISTSIMU: `${HOST}${VERSION}admin/simulation/Simulate/`,
    LISTREQUESTLOAN: `${HOST}${VERSION}admin/simulation/listRequestLoans/`,
    FILTERREQUESTLOAN: `${HOST}${VERSION}admin/simulation/filterRequestLoans/`,
    LISTREQUESTLOANCLIENT: `${HOST}${VERSION}admin/simulation/listRequestLoanClient/`,
    CREATESTATUSLOAN: `${HOST}${VERSION}admin/simulation/createStatusLoan/`,
    CREATESIGNATURELOG: `${HOST}${VERSION}admin/simulation/createSignatureLog/`,
    CREATESIGNATURECCRLOG: `${HOST}${VERSION}admin/simulation/createSignatureCCRLog/`,
    COUNTLOANS: `${HOST}${VERSION}admin/simulation/countLoans/`,
    COUNTLOANSCLIENT: `${HOST}${VERSION}admin/simulation/countLoansClient/`,
    TOTALNEWREQUEST: `${HOST}${VERSION}admin/simulation/totalNewRequest/`,
    LISTSTATUSLOAN: `${HOST}${VERSION}admin/simulation/listLoanStatus/`,
    LISTQUOTAS: `${HOST}${VERSION}admin/simulation/listQuotas/`,
    CREATEREQUESTLOAN: `${HOST}${VERSION}admin/simulation/createRequestLoan/`,
    ADDQUOTAS: `${HOST}${VERSION}admin/simulation/addQuotas/`,
    ADDQUOTASMODIFYVALUE: `${HOST}${VERSION}admin/simulation/addQuotasModifyValue/`,
    UPLOADPROOFPAY: `${HOST}${VERSION}admin/simulation/uploadProofPayment/`,
    UPDATESTATUSQUOTA: `${HOST}${VERSION}admin/simulation/updateStatusQuota/`,
    LISTPROOFPAYMENT: `${HOST}${VERSION}admin/simulation/listProofPayment/`,
    VALIDPROOFQUOTA: `${HOST}${VERSION}admin/simulation/validProofPayment/`,
    LISTCARTERA: `${HOST}${VERSION}admin/simulation/listCartera/`,
    VALUEPAYANDPENDING: `${HOST}${VERSION}admin/simulation/valuePayAndPending/`,

    LISTUSERSAUTOCOMPLETE: `${HOST}${VERSION}admin/users/listUserAutocomplete/`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create/`,
    UPDATEUSERS: `${HOST}${VERSION}admin/users/update/`,
    UPDATESTATUS: `${HOST}${VERSION}admin/users/updateStatus/`,

    //tickets
    LISTTICKETS: `${HOST}${VERSION}admin/tickets/list/`,
    LISTTICKETSUSER: `${HOST}${VERSION}admin/tickets/listUser/`,
    SEARCHPRIORITY: `${HOST}${VERSION}admin/tickets/searchPriority/`,
    CREATETICKET: `${HOST}${VERSION}admin/tickets/create/`,
    DETAILSTICKET: `${HOST}${VERSION}admin/tickets/listDetails/`,
    QUALIFICATIONTICKET: `${HOST}${VERSION}admin/tickets/listQualification/`,
    SAVEQUALIFICATION: `${HOST}${VERSION}admin/tickets/saveQualification/`,
    CREATEDETAILSTICKET: `${HOST}${VERSION}admin/tickets/createDetails/`,
    LISTUPLOADSTICKET: `${HOST}${VERSION}admin/tickets/listUpload/`,
    CREATEUPLOADTICKETS: `${HOST}${VERSION}admin/tickets/createUpload/`,

    // MODULES
    LISTMODULES: `${HOST}${VERSION}admin/modules/list/`,
    CREATEMODULES: `${HOST}${VERSION}admin/modules/create/`,
    UPDATEMODULES: `${HOST}${VERSION}admin/modules/update/`,
    UPDATESTATUSMODULES: `${HOST}${VERSION}admin/modules/updatestatus/`,
    // PRODUCT
    LISTPRODUCT: `${HOST}${VERSION}admin/modules/listProduct/`,
    UPDATEPRODUCT: `${HOST}${VERSION}admin/modules/updateproduct/`,
    UPDATESTATUSPRODUCT: `${HOST}${VERSION}admin/modules/updatestatusproduct/`,

    // STUDENTS
    CREATESTUDENTS: `${HOST}${VERSION}admin/students/createstudents/`,
    LISTSTUDENTS: `${HOST}${VERSION}admin/students/liststudents/`,
    LISTSTUDENTSREGISTRATION: `${HOST}${VERSION}admin/students/liststudentregistration/`,
    UPDATESTUDENTS: `${HOST}${VERSION}admin/students/updatestudents/`,
    // STUDENTS REGISTRATION
    SEARCHSTUDENTS: `${HOST}${VERSION}admin/students/searchstudent/`,
    SEARCHCOURSE: `${HOST}${VERSION}admin/students/searchcourse/`,
    SEARCHCOURSEDESCRIPTION: `${HOST}${VERSION}admin/students/searchcoursedescription/`,
    STUDENTENROLLMENT: `${HOST}${VERSION}admin/students/studentenrollment/`,
    LISTTEACHERENROLLMENT: `${HOST}${VERSION}admin/students/listteacherenrollment/`,
    UPLOADFILEPRINT: `${HOST}${VERSION}admin/students/uploadFingerPrint/`,
    LISTSTUDENTSAUTOCOMPLETE: `${HOST}${VERSION}admin/students/liststudentsAutoComplete/`,
    SEARCHREGISTRATION: `${HOST}${VERSION}admin/students/searchregistration/`,
    SEARCHREGISTRATIONSTUDENTS: `${HOST}${VERSION}admin/students/searchregistrationstudents/`,
    UPDATEREGISTRATIONSTUDENTS: `${HOST}${VERSION}admin/students/updateregistrationstudents/`,

    // TEACHER
    LISTTEACHER: `${HOST}${VERSION}admin/teacher/listTeacher/`,
    CREATETEACHER: `${HOST}${VERSION}admin/teacher/create/`,
    UPDATETEACHER: `${HOST}${VERSION}admin/teacher/update/`,
    LISTTEACHERAUTOCOMPLETE: `${HOST}${VERSION}admin/teacher/listTeacherAutoComplete/`,
    // TEACHER REGISTRATION
    SEARCHTYPECATEGORYTEACHER: `${HOST}${VERSION}admin/parameter/searchtypecategoryteacher/`,
    SEARCHTEACHER: `${HOST}${VERSION}admin/teacher/searchTeacher/`,
    LISTMODULETEACHER: `${HOST}${VERSION}admin/teacher/listModuleTeacher/`,
    DELETEMODULETEACHER: `${HOST}${VERSION}admin/teacher/deleteModuleTeacher/`,
    MODULETEACHER: `${HOST}${VERSION}admin/teacher/moduleTeacher/`,
    // LIST ESTUDENTS TEACHER
    LISTSTUDENTSTEACHER: `${HOST}${VERSION}admin/teacher/listestudentsteacher/`,
    SAVEEVIDENCECOURSE: `${HOST}${VERSION}admin/teacher/saveevidencecourse/`,
    SEARCHEVIDENCE: `${HOST}${VERSION}admin/teacher/searchevidence/`,
    SEARCHSESSION: `${HOST}${VERSION}admin/teacher/searchsession/`,
    UPDATESTATUSEVIDENCE: `${HOST}${VERSION}admin/teacher/updatestatusevidence/`,

    // REPORT
    LISTSTUDENTHOURS: `${HOST}${VERSION}admin/reports/listStudentHours/`,
    LISTSTUDENTDATE: `${HOST}${VERSION}admin/reports/listStudentDate/`,
    LISTSTUDENTHOURSID: `${HOST}${VERSION}admin/reports/listStudentHoursid/`,
    // ATTENDANT
    SEARCHATTENDANT: `${HOST}${VERSION}admin/attendant/searchattendant/`,

    //NOTIFY
    CREATENOTIFYALL: `${HOST}${VERSION}admin/parameter/notifyWhastsappAll/`,
    CREATENOTIFYMODULE: `${HOST}${VERSION}admin/parameter/notifyWhastsappModule/`,
    CREATENOTIFYINDIVIDUAL: `${HOST}${VERSION}admin/parameter/notifyWhastsappIndividual/`,
    CREATENOTIFYPRIVATE: `${HOST}${VERSION}admin/parameter/notifyWhastsappPrivate/`,
    LISTNOTIFYALL: `${HOST}${VERSION}admin/parameter/listNotifyWhastsapp/`,
    LISTNOTIFYWHATSAPPATTENDANT: `${HOST}${VERSION}admin/parameter/listNotifyWhastsappAttendant/`,

    //AGREEMENT
    LISTAGREEMENT: `${HOST}${VERSION}admin/agreements/list_agreements/`,

    //VIEWIWILLPAY
    VIEWIWILLPAY: `${HOST}${VERSION}admin/reports/viewIWillPay/`,
    VIEWIWILLPAYFORMAT: `${HOST}${VERSION}admin/reports/viewIWillPayFormat/`,
    VIEWRISKCENTER: `${HOST}${VERSION}admin/reports/viewRiskCenter/`,
    VIEWPAYROLLDISCOUNT: `${HOST}${VERSION}admin/reports/viewPayrollDiscount/`,
    FORWARDIWILLPAY: `${HOST}${VERSION}admin/simulation/forWardIWillPay/`,
    FORWARDCCR: `${HOST}${VERSION}admin/simulation/forWardCCR/`,
}

export default APP_URL
import React, { createContext, useState } from 'react'

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    return window.sessionStorage.getItem('token')
  })
  const [testLog, setTestLog] = useState("on")

  const value = {
    testLog,
    isAuth,
    activateAuth: (token, user, log) => {
      setTestLog(log)
      setIsAuth(token)
      window.sessionStorage.setItem('token', token)
      window.sessionStorage.setItem('user', JSON.stringify(user))
    }
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

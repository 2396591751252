import React, { useContext } from "react"
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle,Row, Col, Modal, ModalHeader, Input, ModalFooter, ModalBody, Label, Button, FormGroup } from "reactstrap"
import axios from "axios"
import * as Icon from "react-feather"
import { UserContext } from './../../../context/context'
import { URL_IMG } from "../../../config"
import imgDefault from './../../../assets/img/logo/avatarDefault.png?'
import { FaUser } from 'react-icons/fa';
import { PURGE } from 'redux-persist';
import { useDispatch } from 'react-redux';
import { FaUserLock } from "react-icons/fa";
import API from '../../../providers/api';
import { ToastContainer, toast } from 'react-toastify';
import { Lock, User, Check } from "react-feather";
import { HiOutlinePhotograph, HiOutlineInformationCircle } from "react-icons/hi";
import { Link } from 'react-router-dom';


const UserDropdown = props => {
  const { activateAuth } = useContext(UserContext)
  const dispatch = useDispatch();
  // console.log("const UserDropdown = props => {",props);
  // console.log("URL CLIENTE ",`${HOST}/cliente`);
  return (
    <DropdownMenu >
      {
        props.profile === 1 && <>{
          <DropdownItem tag={Link} to={"/cliente"}>
            <FaUser size={17} color={'#626262'} className="mr-50" />
            <span style={{ color: '#626262' }} className="align-middle">Mi Perfil</span>
          </DropdownItem>
        }</>
      }
      <DropdownItem onClick={props.modalUpdatePhoto}>
        <HiOutlinePhotograph size={17} color={'#626262'} className="mr-50" />
        <span style={{ color: '#626262' }} className="align-middle">Cambiar Foto</span>
      </DropdownItem>
      <DropdownItem onClick={props.modalUpdatePass}>
        <FaUserLock size={17} color={'#626262'} className="mr-50" />
        <span style={{ color: '#626262' }} className="align-middle">Cambiar Contraseña</span>
      </DropdownItem>
      <DropdownItem onClick={() => {
        activateAuth(null, null, "off")
        sessionStorage.clear();
        dispatch({
          type: PURGE,
          key: 'root', // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
          result: () => null // Func expected on the submitted action.
        });

      }}>
        <Icon.Power size={16} color={'#626262'} className="mr-50" />
        <span style={{ color: '#626262' }} className="align-middle">Cerrar sesión</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
    modalUpdatePass: false,
    toggleModalUpdatePass: () => {this.setState({modalUpdatePass:!this.state.modalUpdatePass})},
    modalUpdatePhoto: false,
    toggleModalUpdatePhoto: () => {this.setState({modalUpdatePhoto:!this.state.modalUpdatePhoto})},
    idUser:0,
    pass:'',
    passConf:'',
    fileName:'',
    file:'',
  }
  user = (JSON.parse(sessionStorage.getItem('user')))

  componentDidMount() {
    // console.log(this.user);
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult })
    })
  }

  UpdatePass = () => {
    if (this.state.pass === "") {
        toast.warn('Debe agregar su nueva contraseña de al menos 6 carácteres.');
    }else if (this.state.passConf !== this.state.pass) {
        toast.warn('Las contraseñas no coinciden.');
    } else {
        let id = this.user.iduser
        API.updatePass(id, this.state.pass)
            .then(data => data.json())
            .then(dataJson => {
                if (dataJson.success === true) {
                    toast.success(dataJson.message)
                    this.setState({modalUpdatePass:!this.state.modalUpdatePass})
                } else {
                    toast.warn(dataJson.message)
                }
            })
            .catch((e) => {
                console.log("Ups! Ha sucedio algo >>>", e)
            })
    }
  }
  
  UploadPhoto = () => {
    let arrayStorage = this.user
    // console.log("this.state.fileName",this.state.fileName);
    if(this.state.fileName === ''){
        toast.warn("Debe Seleccionar la imagen a subir.")
    }else{
        let id = this.user.iduser
        API.uploadPhoto(id, this.state.fileName)
            .then(data => data.json())
            .then(dataJson => {
                // console.log("API.uploadPhoto(idUser, fileName)", dataJson.location);
                arrayStorage.photo=dataJson.location
                if (dataJson.success === true) {
                    toast.success(dataJson.message)

                    window.sessionStorage.setItem('user', JSON.stringify(arrayStorage))
                    window.location.reload()
                    // setModalUpdatePhoto(false)
                } else {
                    toast.warn(dataJson.message)
                }
            })
            .catch((e) => {
                console.log("Ups! Ha sucedio algo >>>", e)
            })
    }
}
  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch
    })
  }
  onChangeFile = (e) => {
      this.setState({fileName:e.target.files[0]})
      if(e.target.files[0] !== undefined){
         this.setState({file:URL.createObjectURL(e.target.files[0])})
      }else {
        this.setState({fileName:''})
        this.setState({file:''})
      }
  }

  // const [modalUpdatePass, setModalUpdatePass] = useState(false);
  // toggleModalUpdatePass = () => {setModalUpdatePass(!this.state.modalUpdatePass)};

  render() {
    // console.log("class NavbarUser extends React.PureComponent {",this.user)
    return (<>
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav text-white d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {`${this.user.name}`}
              </span>
              <span className="user-status">
                {this.user.profile === 2 ? 'Administrador' : 'User'}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={this.user.photo ? this.user.photo : imgDefault}// `${URL_IMG}/${this.user.photo}`
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown props={this.props}
            modalUpdatePass={this.state.toggleModalUpdatePass}
            modalUpdatePhoto={this.state.toggleModalUpdatePhoto}
            profile={this.user.profile}
          />
        </UncontrolledDropdown>
      </ul>
      
      {/* Modal update password */}
      <Modal isOpen={this.state.modalUpdatePass} toggle={this.state.toggleModalUpdatePass} size='lg'>
          <ModalHeader toggle={this.state.toggleModalUpdatePass}>
              Actualizar Contraseña
          </ModalHeader>
          <ModalBody>
              <Row className="d-flex justify-content-center">
                  {/* <Input type='hidden' value={this.state.idUser} onChange={e => this.setState({idUser:e.target.value})} /> */}
                  <Col lg="6">
                      <Label className="fontLabel">Nueva Contraseña</Label>
                      <FormGroup className="form-label-group position-relative has-icon-left">
                          <Input type="password" placeholder="New Password" value={this.state.pass} onChange={e => this.setState({pass:e.target.value})} />
                          <div className="form-control-position">
                              <Lock size={15} />
                          </div>
                      </FormGroup>
                  </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                  <Col lg="6">
                      <Label className="fontLabel">Confirmar Contraseña</Label>
                      <FormGroup className="form-label-group position-relative has-icon-left">
                          <Input type="password" placeholder="Confirm Password" value={this.state.passConf} onChange={e => this.setState({passConf:e.target.value})} />
                          <div className="form-control-position">
                              <Lock size={15} />
                          </div>
                      </FormGroup>
                  </Col>
              </Row>
          </ModalBody>
          <ModalFooter>
              <Button color='secondary' onClick={this.state.toggleModalUpdatePass}>Cerrar</Button>
              <Button color='primary' onClick={this.UpdatePass}>Actualizar</Button>
          </ModalFooter>
      </Modal>

      {/* Modal uploadPhoto del cliente */}
      <Modal isOpen={this.state.modalUpdatePhoto} toggle={this.state.toggleModalUpdatePhoto} size='lg'>
          <ModalHeader toggle={this.state.toggleModalUpdatePhoto}>
              Actualizar Foto
          </ModalHeader>
          <ModalBody>
              <Row >
                  <Col>
                      <Label>Subir Imagen</Label>
                      <Input type='file' accept=".png,.jpeg,.jpg" style={{padding:"0px"}} onChange={e => this.onChangeFile(e)} />
                      {/* <UpFiles /> */}
                  </Col>
              </Row>
              <Row>
                  <Col lg="12">
                              {
                                // console.log('this.state.file',this.state.file)
                              this.state.file !== '' &&
                              // this.state.file.map((img, index) => (
                                  <>
                                      {
                                          this.state.fileName.type === 'image/png' || this.state.fileName.type === 'image/jpeg' || this.state.fileName.type === 'image/jpg' ? (
                                              <div style={{ width: "100%" }}>
                                                  <img src={this.state.file} width="35%" height="35%" />
                                              </div>
                                          ) : ""
                                      }
                                  </>
                              // ))
                              }
                  </Col>
              </Row>
          </ModalBody>
          <ModalFooter>
              <Button color='secondary' onClick={this.state.toggleModalUpdatePhoto}>Cerrar</Button>
              <Button color='primary' onClick={this.UploadPhoto}>Cargar</Button>
          </ModalFooter>
      </Modal>

      {/* <ToastContainer /> */}
      </>
    )
  }
}
export default NavbarUser

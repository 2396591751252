import React from "react";
import { FaRegBell, FaHome, FaCheck, FaRegSun, FaUser, FaCommentDollar,
  FaShopify, FaUserTag, FaUserTie, FaListUl, FaBook, FaCaretRight,
  FaRegClipboard, FaCalculator, FaTicketAlt, FaHandHoldingUsd } from 'react-icons/fa';
import { HiUserGroup } from 'react-icons/hi';
import { BsWallet } from "react-icons/bs";
const user = JSON.parse(sessionStorage.getItem('user'))

const navigationSuperAdmin = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "usuarios",
    title: "Conf. Usuarios",
    type: "collapse",
    icon: <FaRegSun size={19} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "usuarios1",
        title: "Usuarios",
        type: "item",
        icon: <FaUser size={17}  style={{color:"white"}} />,
        permissions: ["admin", "editor"],
        navLink: "/usuarios"
      },
      {
        id: "profile",
        title: "Perfiles",
        type: "item",
        icon: <FaUser size={17}  style={{color:"white"}} />,
        permissions: ["admin", "editor"],
        navLink: "/profile"
      },
    ]
  },
  {
    id: "clientes",
    title: "Clientes",
    type: "item",
    icon: <FaUser size={19} />,
    permissions: ["admin", "editor"],
    navLink: "/clientes"
  },
  {
     id: "fintech",
     title: "Fintech",
     type: "collapse",
     icon: <FaCommentDollar size={20} style={{color:"white"}} />,
     permissions: ["admin", "editor"],
     children: [
       {
         id: "confSimulation",
         title: "Conf. Simulación",
         type: "item",
         icon: <FaRegSun size={17}  style={{color:"white"}} />,
         permissions: ["admin", "editor"],
         navLink: "/confSimulation",
       },
       {
         id: "agreement",
         title: "Convenios",
         type: "item",
         icon: <HiUserGroup size={17}  style={{color:"white"}} />,
         permissions: ["admin", "editor"],
         navLink: "/agreement",
       },
      //  {
      //    id: "simulation",
      //    title: "Simulación",
      //    type: "item",
      //    icon: <FaCalculator size={17}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/simulation",
      //  },
      //  {
      //    id: "requestLoan",
      //    title: "Solicitud",
      //    type: "item",
      //    icon: <FaHandHoldingUsd size={20}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/requestLoan",
      //  },
     ]
   },
   {
      id: "requestLoan",
      title: "Solicitudes de Crédito",
      type: "collapse",
      icon: <FaHandHoldingUsd size={20} style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      children: [
        {
          id: "requestNew",
          title: "Nuevas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/newLoans",
        },
        {
          id: "requestInProcess",
          title: "En Curso",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/inProcessLoans",
        },
        {
          id: "requestApprov",
          title: "Aprobadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/approvedLoans",
        },
        {
          id: "accountingLoan",
          title: "Para Contabilidad",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/accountingLoan",
        },
        {
          id: "disbursementLoan",
          title: "Para Desembolsar",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/disbursementLoan",
        },
        {
          id: "finishLoan",
          title: "Solicitudes Finalizadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/finishLoan",
        },
        {
          id: "requestDecline",
          title: "Solicitudes Rechazadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/declineLoans",
        },
      ]
    },
    {
      id: "requestDeliver",
      title: "Cartera",
      type: "item",
      icon: <BsWallet size={20}  style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      navLink: "/deliverLoans",
    },
];

const navigationUser = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  // {
  //   id: "client",
  //   title: "Mi Perfil",
  //   type: "collapse",
  //   icon: <FaUser size={19} />,
  //   permissions: ["admin", "editor"],
  //   children: [
  //     {
  //       id: "client1",
  //       title: "Información",
  //       type: "item",
  //       icon: <FaUser size={15}  style={{color:"white"}} />,
  //       permissions: ["admin", "editor"],
  //       navLink: "/cliente"
  //     },
  //   ]
  // },
  {
     id: "requestLoan",
     title: "Mis Solicitudes",
     type: "item",
     icon: <FaCommentDollar size={20} style={{color:"white"}} />,
     permissions: ["admin", "editor"],
     navLink: "/requestLoan",
    //  children: [
      //  {
      //    id: "confSimulation",
      //    title: "Conf. Simulación",
      //    type: "item",
      //    icon: <FaRegSun size={17}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/confSimulation",
      //  },
      //  {
      //    id: "agreement",
      //    title: "Convenios",
      //    type: "item",
      //    icon: <HiUserGroup size={17}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/agreement",
      //  },
      //  {
      //    id: "simulation",
      //    title: "Simulación",
      //    type: "item",
      //    icon: <FaCalculator size={17}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/simulation",
      //  },
      //  {
      //    id: "requestLoan",
      //    title: "Solicitud",
      //    type: "item",
      //    icon: <FaHandHoldingUsd size={20}  style={{color:"white"}} />,
      //    permissions: ["admin", "editor"],
      //    navLink: "/requestLoan",
      //  },
    //  ]
   },
];

const navigationContabilidad = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "clientes",
    title: "Clientes",
    type: "item",
    icon: <FaUser size={19} />,
    permissions: ["admin", "editor"],
    navLink: "/clientes"
  },
  {
      id: "requestLoan",
      title: "Solicitudes de Crédito",
      type: "collapse",
      icon: <FaHandHoldingUsd size={20} style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      children: [
        {
          id: "requestApprov",
          title: "Aprobadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/approvedLoans",
        },
        {
          id: "requestDecline",
          title: "Solicitudes Rechazadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/declineLoans",
        },
      ]
    },
    {
      id: "requestDeliver",
      title: "Cartera",
      type: "item",
      icon: <BsWallet size={20}  style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      navLink: "/deliverLoans",
    },
];

const navigationTalentoHumano = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "clientes",
    title: "Clientes",
    type: "item",
    icon: <FaUser size={19} />,
    permissions: ["admin", "editor"],
    navLink: "/clientes"
  },
  {
      id: "requestLoan",
      title: "Solicitudes de Crédito",
      type: "collapse",
      icon: <FaHandHoldingUsd size={20} style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      children: [
        {
          id: "requestInProcess",
          title: "En Curso",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/inProcessLoans",
        },
      ]
    },
];

const navigationDesembolso = [
  {
    id: "home",
    title: "Inicio",
    type: "item",
    icon: <FaHome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "clientes",
    title: "Clientes",
    type: "item",
    icon: <FaUser size={19} />,
    permissions: ["admin", "editor"],
    navLink: "/clientes"
  },
  {
      id: "requestLoan",
      title: "Solicitudes de Crédito",
      type: "collapse",
      icon: <FaHandHoldingUsd size={20} style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      children: [
        {
          id: "disbursementLoan",
          title: "Para Desembolsar",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/disbursementLoan",
        },
        {
          id: "finishLoan",
          title: "Solicitudes Finalizadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/finishLoan",
        },
        {
          id: "requestDecline",
          title: "Solicitudes Rechazadas",
          type: "item",
          icon: <FaCaretRight size={17}  style={{color:"white"}} />,
          permissions: ["admin", "editor"],
          navLink: "/declineLoans",
        },
      ]
    },
    {
      id: "requestDeliver",
      title: "Cartera",
      type: "item",
      icon: <BsWallet size={20}  style={{color:"white"}} />,
      permissions: ["admin", "editor"],
      navLink: "/deliverLoans",
    },
];


const navigationConfig =
  user?.profile === 2 ? navigationSuperAdmin :
    user?.profile === 1 ? navigationUser :
      user?.profile === 3 ? navigationContabilidad :
        user?.profile === 4 ? navigationTalentoHumano :
          user?.profile === 5 ? navigationDesembolso :
            []

export default navigationConfig

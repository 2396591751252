import { array } from 'prop-types';
import URL_ROUTE from '../constants/index';
let token = sessionStorage.getItem('token');
class Api {
    async login(identification, password) {
        let data = {
            identification,
            password
        };
        const query = await fetch(`${URL_ROUTE.LOGIN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async loginWeb(identification, password, value, valuemonth, typequota, current_iva, currentadminmonth, currenteffectiverate, currentinsurance, currentcreditanalysis, quota, daterequest, currentperiod) {
        let data = { identification, password, value, valuemonth, typequota, current_iva, currentadminmonth, currenteffectiverate, currentinsurance, currentcreditanalysis, quota, daterequest, currentperiod};
        // console.log("QQQQQ",data);
        const query = await fetch(`${URL_ROUTE.LOGINWEB}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }
    async recoveryPass(emailRecovery) {
        let data = { emailRecovery }
        const query = await fetch(`${URL_ROUTE.RECOVERYPASS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async isLoggedInWS(phone) {
        let data = {
            phone
        };
        const query = await fetch(`${URL_ROUTE.WAPI_ISLOGIN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /*****************API GET STATES/CITIES*****************/

    // PARAMETER
    async searchParams(idtop) {
        let data = {
            idtop
        }
        const query = await fetch(`${URL_ROUTE.SEARCHPARAMS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchtypecategory(aux) {
        let data = {
            aux
        }
        const query = await fetch(`${URL_ROUTE.SEARCHTYPECATEGORY}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createparameter(id, idtop, name, aux) {
        let data = {
            id, idtop, name, aux
        }
        const query = await fetch(`${URL_ROUTE.CREATEPARAMETER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async deleteparameter(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETEPARAMETER}`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    //Tickets
    async listTickets() {
        const query = await fetch(`${URL_ROUTE.LISTTICKETS}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return query
    }

    async listTicketsUser(idusercreate) {
        let data = {
            idusercreate
        }
        const query = await fetch(`${URL_ROUTE.LISTTICKETSUSER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchPriority(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHPRIORITY}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createTicket(idrequest, idtypecontactus, idcampus, idstatus, iduser, idusercreate, details) {
        let data = { idstatus, iduser, idtypecontactus, idcampus, idrequest, idusercreate, details }
        // console.log(data, "data");
        const query = await fetch(`${URL_ROUTE.CREATETICKET}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createDetailsTicket(idticket, details, idstatus, idusercreate) {
        let data = { idticket: parseInt(idticket), details, idstatus: parseInt(idstatus), idusercreate: parseInt(idusercreate) }
        const query = await fetch(`${URL_ROUTE.CREATEDETAILSTICKET}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async qualificationTicket(idticket) {
        let data = {
            idticket
        }
        const query = await fetch(`${URL_ROUTE.QUALIFICATIONTICKET}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveQualification(idticket, qualification, observationQual, idusercreate) {
        let data = { idticket, qualification, observationQual, idusercreate }
        const query = await fetch(`${URL_ROUTE.SAVEQUALIFICATION}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async detailsTicket(idticket) {
        let data = {
            idticket
        }
        const query = await fetch(`${URL_ROUTE.DETAILSTICKET}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listUploadsTicket(idticket) {
        let data = {
            idticket
        }
        const query = await fetch(`${URL_ROUTE.LISTUPLOADSTICKET}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createUploadsTicket(idticket, upload) {
        let data = new FormData()
        data.append('idticket', idticket)
        data.append('upload', upload)
        const query = await fetch(`${URL_ROUTE.CREATEUPLOADTICKETS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }

    /******************* USERS *******************/
    async searchUser(identification) {
        let data = {identification}
        const query = await fetch(`${URL_ROUTE.SEARCHUSER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listUsers() {
        const query = await fetch(`${URL_ROUTE.LISTUSERS}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return query
    }
    async createUser(name, last_name, identification, phone_number, profile, email) {
        let data = {name, last_name, identification, phone_number, profile, email }
        const query = await fetch(`${URL_ROUTE.CREATEUSER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateUser(id, identification, name, last_name, phone_number, profile, email) {
        let data = {id, identification, name, last_name, phone_number, profile, email }
        const query = await fetch(`${URL_ROUTE.UPDATEUSER}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updatePass(id, password) {
        let data = {id, password }
        const query = await fetch(`${URL_ROUTE.UPDATEPASS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listSelectDpto() {
        const query = await fetch(`${URL_ROUTE.LISTSELECTDPTO}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async listSelectCitie(dpto) {
        let data = { dpto }
        const query = await fetch(`${URL_ROUTE.LISTSELECTCITIE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /*************************** CLIENTES ****************************/
    async searchDetail(identification) {
        let data = {identification}
        const query = await fetch(`${URL_ROUTE.SEARCHDETAIL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listClients() {
        const query = await fetch(`${URL_ROUTE.LISTCLIENTS}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async listDocs(idClient) {
        let data = {idClient}
        const query = await fetch(`${URL_ROUTE.LISTDOCSCLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async infoClient(idClient) {
        let data = {idClient}
        const query = await fetch(`${URL_ROUTE.INFOCLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createClient(name, last_name, phone_number, identification, home_address, email, citie, birth_date, ethnicity) {
        let data = {name, last_name, phone_number, identification, home_address, email, citie, birth_date, ethnicity }
        const query = await fetch(`${URL_ROUTE.CREATECLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateClient(idUser, idUserDetail, name, last_name, phone_number, identification, home_address, work_address, email,
        citie, birth_date, dateExpDoc, citieExpDoc, genre, education, maritalStatus, typeResidence, typeJob,
        company, economicActivity, personalBank, numAccount, income, payFrequency, ocupation, agreement, ethnicity) {
        if(work_address == null)
            work_address = "N/A"
        if(company == null)
            company = "N/A"
        if(economicActivity == null)
            economicActivity = "N/A"
        if(genre === "")
            genre = 0
        if(education === "")
            education = 0
        if(maritalStatus === "")
            maritalStatus = 0
        if(typeResidence === "")
            typeResidence = 0
        if(agreement === null)
            agreement = 0
        // console.log("idUser",idUser, "idUserDetail",idUserDetail, "name",name, "last_name",last_name, "phone_number",phone_number, "identification",identification, "home_address",home_address, "work_address",work_address, "email",email,
        //     "citie",citie, "birth_date",birth_date, "dateExpDoc",dateExpDoc, "citieExpDoc",citieExpDoc, "genre",genre, "education",education, "maritalStatus",maritalStatus, "typeResidence",typeResidence, "typeJob",typeJob,
        //     "company",company, "economicActivity",economicActivity, "personalBank",personalBank, "numAccount",numAccount, "income",income, "payFrequency",payFrequency, "ocupation",ocupation, "agreement",agreement);

        let data = {idUser, idUserDetail, name, last_name, phone_number, identification, home_address, work_address, email,
            citie, birth_date, dateExpDoc, citieExpDoc, genre, education, maritalStatus, typeResidence, typeJob,
            company, economicActivity, personalBank, numAccount, income, payFrequency, ocupation, agreement, ethnicity }
        const query = await fetch(`${URL_ROUTE.UPDATECLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createWebClient(name, last_name, identification, email, phone_number, pass, value, valuemonth, typequota, current_iva, currentadminmonth, currenteffectiverate, currentinsurance, currentcreditanalysis, quota, daterequest, currentperiod, agreements) {
        let data = {name, last_name, identification, email, phone_number, pass, value, valuemonth, typequota, current_iva, currentadminmonth, currenteffectiverate, currentinsurance, currentcreditanalysis, quota, daterequest, currentperiod, agreements: agreements != "" ? agreements : '-' }
        const query = await fetch(`${URL_ROUTE.CREATEWEBCLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createWebClientAlone(name, last_name, identification, email, phone_number, pass, agreements) {
        let data = {name, last_name, identification, email, phone_number, pass, agreements: agreements != "" ? String(agreements) : '-'}
        const query = await fetch(`${URL_ROUTE.CREATEWEBCLIENTALONE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async uploadClients(path) {
        let data = new FormData()
        data.append('upload', path)
        const query = await fetch(`${URL_ROUTE.UPLOADCLIENTS}`, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: data,
        });
        return query
    }
    async uploadDocClient(idUser, upload, typeDoc) {
        // console.log("uploadDocClient",upload);
        let data = new FormData()
        data.append('iduser', idUser)
        data.append('type_doc', typeDoc)
        data.append('upload', upload[0])
        const query = await fetch(`${URL_ROUTE.UPLOADDOCCLIENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }
    async validDocClient(idclient) {
        let data = {idclient }
        const query = await fetch(`${URL_ROUTE.VALIDDOCCLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateDocClient(idFile, upload, typeDoc) {
        let data = new FormData()
        data.append('id', idFile)
        data.append('type_doc', typeDoc)
        data.append('upload', upload[0])
        const query = await fetch(`${URL_ROUTE.UPDATEDOCCLIENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }
    async createComplementInfo(iduser,birth_date, dateExpDoc, citieExpDoc, genre, education, maritalStatus, home_address, citie, typeResidence, typeJob, company, economicActivity, personalBank, income, payFrequency, ocupation,numAccount,arrayFiles, ethnicity) {
        let data = new FormData()
        data.append('iduser', iduser)
        data.append('birth_date',birth_date)
        data.append('dateExpDoc',dateExpDoc)
        data.append('citieExpDoc',citieExpDoc)
        data.append('genre',genre)
        data.append('education',education)
        data.append('maritalStatus',maritalStatus)
        data.append('home_address',home_address)
        data.append('citie',citie)
        data.append('typeResidence',typeResidence)
        data.append('typeJob',typeJob)
        data.append('company',company)
        data.append('economicActivity',economicActivity)
        data.append('personalBank',personalBank)
        data.append('income',income)
        data.append('payFrequency',payFrequency)
        data.append('ocupation',ocupation)
        data.append('numAccount',numAccount)
        data.append('ethnicity',ethnicity)
        arrayFiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        
        // let data = {iduser, birth_date, dateExpDoc, citieExpDoc, genre, education, maritalStatus, home_address, citie, typeResidence, typeJob, company, economicActivity, personalBank, income, payFrequency, ocupation, numAccount }
        const query = await fetch(`${URL_ROUTE.CREATECOMPLEMENTINFO}`, {
            method: 'POST',
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: data,
        });
        return query
    }
    async uploadPhoto(id, photo) {
        let data = new FormData()
        data.append('id', id)
        if(photo.length > 0){
            data.append('upload', photo[0])
        }else{
            data.append('upload', photo)
        }
        const query = await fetch(`${URL_ROUTE.UPLOADPHOTO}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }

    //********************* AGREEMENTS ****************** */
    async listAgreements() {
        const query = await fetch(`${URL_ROUTE.LISTAGREE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async listSelectAgreements() {
        const query = await fetch(`${URL_ROUTE.LISTSELECTAGREE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async listSelectAgreementsWeb() {
        const query = await fetch(`${URL_ROUTE.LISTSELECTAGREEWEB}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return query
    }
    async createAgreement(name, nit, phone_number, address, agent_legal, email) {
        let data = {name, nit, phone_number, address, agent_legal, email }
        const query = await fetch(`${URL_ROUTE.CREATEAGREE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateAgreement(id, name, nit, phone_number, address, agent_legal, email) {
        let data = {id, name, nit, phone_number, address, agent_legal, email }
        const query = await fetch(`${URL_ROUTE.UPDATEAGREE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchAgreement(name) {
        let data = {name }
        const query = await fetch(`${URL_ROUTE.SEARCHAGREE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    //********************* PROFILE ****************** */
    async listProfile() {
        const query = await fetch(`${URL_ROUTE.LISTPROFILE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async createProfile(name, description) {
        let data = {name, description }
        const query = await fetch(`${URL_ROUTE.CREATEPROFILE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateProfile(id, name, description, status) {
        let data = {id, name, description, status }
        const query = await fetch(`${URL_ROUTE.UPDATEPROFILE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listSelectProfile() {
        const query = await fetch(`${URL_ROUTE.LISTSELECTPROFILE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    /******************** SIMULATION *********************/
    async createParamsCredit(min_loan_amount, max_loan_amount, IVA, effecAnnualRate, insurance, monthAdministration, creditAnalysis, quota, periodAnnual, fourThousand) {
        let data = {min_loan_amount, max_loan_amount, IVA, effecAnnualRate, insurance, monthAdministration, creditAnalysis, quota, periodAnnual, fourThousand }
        const query = await fetch(`${URL_ROUTE.CREATEPARAMSCREDIT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateParamsCredit(id, min_loan_amount, max_loan_amount, IVA, effecAnnualRate, insurance, monthAdministration, creditAnalysis, quota, periodAnnual, fourThousand) {
        console.log("fourThousand", fourThousand);
        let data = {id, min_loan_amount, max_loan_amount, IVA, effecAnnualRate, insurance, monthAdministration, creditAnalysis, quota, periodAnnual, fourThousand }
        const query = await fetch(`${URL_ROUTE.UPDATEPARAMSCREDIT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async addSimulate(quota, percentage, signature, third, idConf) {
        let data = {quota, percentage, signature, third, idConf }
        const query = await fetch(`${URL_ROUTE.CREATESIMU}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async updateSimulate(idSimu, quota, percentage, signature, third) {
        let data = {idSimu, quota, percentage, signature, third }
        const query = await fetch(`${URL_ROUTE.UPDATESIMU}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async configParamsCredit() {
        const query = await fetch(`${URL_ROUTE.LISTCONFSIMU}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }
    async listSimulate(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.LISTSIMU}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listRequestLoans(status) {
        let data = {
            status
        }
        const query = await fetch(`${URL_ROUTE.LISTREQUESTLOAN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async filterRequestLoans(status, date_ini, date_fin) {
        let data = {
            status, date_ini, date_fin
        }
        const query = await fetch(`${URL_ROUTE.FILTERREQUESTLOAN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listLoanStatus(idReques) {
        let data = {
            idReques
        }
        const query = await fetch(`${URL_ROUTE.LISTSTATUSLOAN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listQuotas(idReques) {
        let data = {
            idReques
        }
        const query = await fetch(`${URL_ROUTE.LISTQUOTAS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async listCartera() {
        const query = await fetch(`${URL_ROUTE.LISTCARTERA}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            // body: JSON.stringify(data),
        });
        return query
    }
    async listRequestLoanClient(idClient) {
        let data = {
            idClient
        }
        const query = await fetch(`${URL_ROUTE.LISTREQUESTLOANCLIENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createStatusLoan(idUser,commentStatus, statusLoan, idLoan) {
        let data = {idUser,commentStatus, statusLoan, idLoan}
        const query = await fetch(`${URL_ROUTE.CREATESTATUSLOAN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createSignatureLog(idLoan, url) {
        let data = {idLoan, url}
        const query = await fetch(`${URL_ROUTE.CREATESIGNATURELOG}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createSignatureCCRLog(idLoan, url) {
        let data = {idLoan, url}
        const query = await fetch(`${URL_ROUTE.CREATESIGNATURECCRLOG}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async createRequestLoan(iduser, idloan, value, valuemonth, typequota, iva, valueMonthAdmin, effectiveAnnualCredit, insurance, valueCreditAnalysis, valueQuotaSet, daterequest,period) {
        let data = {iduser, idloan, value, valuemonth, typequota, iva, valueMonthAdmin, effectiveAnnualCredit, insurance, valueCreditAnalysis, valueQuotaSet, daterequest,period}
        const query = await fetch(`${URL_ROUTE.CREATEREQUESTLOAN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async addQuotas(arrayQuotas) {
        let data = {arrayQuotas}
        // console.log("AAA",data, typeof data);
        const query = await fetch(`${URL_ROUTE.ADDQUOTAS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async addQuotasModifyValue(arrayQuotas, value_old, user_name) {
        let data = {arrayQuotas, value_old, user_name}
        // console.log("AAA",data, typeof data);
        const query = await fetch(`${URL_ROUTE.ADDQUOTASMODIFYVALUE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async uploadProofPayment(idquota, upload) {
        let data = new FormData()
        data.append('quota_id', idquota)
        data.append('upload', upload[0])
        const query = await fetch(`${URL_ROUTE.UPLOADPROOFPAY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }
    async listProofPayment(idquota) {
        let data = {
            idquota
        }
        const query = await fetch(`${URL_ROUTE.LISTPROOFPAYMENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async validProofQuota(quota_id, payment) {
        let data = {
            quota_id, payment
        }
        const query = await fetch(`${URL_ROUTE.VALIDPROOFQUOTA}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }


    async listUserAutocomplete() {
        const query = await fetch(`${URL_ROUTE.LISTUSERSAUTOCOMPLETE}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return query
    }

    async updateusers(id, name, lastname, idprofile, idcampus, identification) {
        let data = {
            id, name, lastname, idprofile, idcampus, identification
        }
        const query = await fetch(`${URL_ROUTE.UPDATEUSERS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatus(id, estado) {
        let data = {
            id, estado
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatepass(id, password) {
        let data = {
            id, password
        }
        const query = await fetch(`${URL_ROUTE.UPDATEPASS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // MODULES
    async listModule() {
        const query = await fetch(`${URL_ROUTE.LISTMODULES}`, {
            method: 'GET',
            headers: {
                'Contnet-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async createmodules(typecategory, id_category, data_course, data_product, id_user) {

        const newArray = [];
        data_course.map(data => {
            newArray.push({ ...data, "id_category": id_category, "id_user": id_user })
        })

        const newArrayProduct = [];
        data_product.map(row => {
            newArrayProduct.push({ ...row, "id_category": id_category, "id_user": id_user })
        })

        data_course = JSON.stringify(newArray)
        data_product = JSON.stringify(newArrayProduct)

        let data = {
            typecategory, data_course, data_product
        }

        const query = await fetch(`${URL_ROUTE.CREATEMODULES}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatemodules(id, id_category, name, precie, hour, quotas, hourstart) {
        let data = {
            id, id_category, name, precie, hour, quotas, hourstart
        }
        const query = await fetch(`${URL_ROUTE.UPDATEMODULES}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatusmodules(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSMODULES}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // product
    async listProduct() {
        const query = await fetch(`${URL_ROUTE.LISTPRODUCT}`, {
            method: 'GET',
            headers: {
                'Contnet-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async updateproduct(id, id_category, name, precie) {
        let data = {
            id, id_category, name, precie
        }
        const query = await fetch(`${URL_ROUTE.UPDATEPRODUCT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatusproduct(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSPRODUCT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // students
    async createstudents(id_type_document, document, name, middel_name, last_name, middel_last_name, place_birth, address, neighborhood, stratum, type_housing, phone,
        cell_phone, have_siblings, oldest_child, email, id_insurance, allergies, specify, id_contact, contactName, contactLastName, contactPhone, id_user, dateBirth, tuition_pay, fatherName, fatherCel, fatherWorking, fatherEmail, motherName, motherCel, motherWorking, motherEmail, deposit, depositOther) {
        let data = {
            id_type_document, document, name, middel_name, last_name, middel_last_name, place_birth, address, neighborhood, stratum, type_housing, phone,
            cell_phone, have_siblings, oldest_child, email, id_insurance, allergies, specify, id_contact, contactName, contactLastName, contactPhone, id_user, dateBirth, tuition_pay, fatherName, fatherCel, fatherWorking, fatherEmail, motherName, motherCel, motherWorking, motherEmail, deposit, depositOther
        }
        const query = await fetch(`${URL_ROUTE.CREATESTUDENTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async liststudents() {
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTS}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async liststudentregistration() {
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTSREGISTRATION}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async updatestudents(id, id_type_document, document, name, middel_name, last_name, middel_last_name, place_birth, address, neighborhood, stratum, type_housing, phone,
        cell_phone, have_siblings, oldest_child, email, id_insurance, allergies, specify, id_contact, contactName, contactLastName, contactPhone, dateBirth, tuition_pay, fatherName, fatherCel, fatherWorking, fatherEmail, motherName, motherCel, motherWorking, motherEmail) {
        let data = {
            id, id_type_document, document, name, middel_name, last_name, middel_last_name, place_birth, address, neighborhood, stratum, type_housing, phone,
            cell_phone, have_siblings, oldest_child, email, id_insurance, allergies, specify, id_contact, contactName, contactLastName, contactPhone, dateBirth, tuition_pay, fatherName, fatherCel, fatherWorking, fatherEmail, motherName, motherCel, motherWorking, motherEmail
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTUDENTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchstudent(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHSTUDENTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchcourse(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHCOURSE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchcoursedescription(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHCOURSEDESCRIPTION}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async studentenrollment(id_student, dateRow, id_user) {
        let data = { id_student, dateRow, id_user }
        const query = await fetch(`${URL_ROUTE.STUDENTENROLLMENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listteacherenrollment(id_module, id_category) {
        let data = { id_module, id_category }
        const query = await fetch(`${URL_ROUTE.LISTTEACHERENROLLMENT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadFingerPrint(id, upload) {
        let data = new FormData()
        data.append('id', id)
        data.append('upload', upload)
        const query = await fetch(`${URL_ROUTE.UPLOADFILEPRINT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data
        });
        return query
    }

    async searchregistration(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHREGISTRATION}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchregistrationstudents(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.SEARCHREGISTRATIONSTUDENTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateregistrationstudents(id, id_category, id_module, hour, id_teacher, hourstart, value) {
        let data = { id, id_category, id_module, hour, id_teacher, hourstart, value }
        const query = await fetch(`${URL_ROUTE.UPDATEREGISTRATIONSTUDENTS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });
        return query
    }

    // teacher
    async createteacher(typedocument, document, name, middlename, lastname, middlelastname, gender, phone, cellphone, email) {
        let data = {
            typedocument, document, name, middlename, lastname, middlelastname, gender, phone, cellphone, email
        }
        const query = await fetch(`${URL_ROUTE.CREATETEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateteacher(typedocument, document, name, middlename, lastname, middlelastname, gender, phone, cellphone, email) {
        let data = {
            typedocument, document, name, middlename, lastname, middlelastname, gender, phone, cellphone, email
        }
        const query = await fetch(`${URL_ROUTE.UPDATETEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listTeacher() {
        const query = await fetch(`${URL_ROUTE.LISTTEACHER}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async listTeacherAutoComplete() {
        const query = await fetch(`${URL_ROUTE.LISTTEACHERAUTOCOMPLETE}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async searchTypeCategoryTeacher(aux, document) {
        let data = {
            aux,
            document
        }
        const query = await fetch(`${URL_ROUTE.SEARCHTYPECATEGORYTEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchTeacher(document) {
        let data = {
            document
        }
        const query = await fetch(`${URL_ROUTE.SEARCHTEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listModuleTeacher(document) {
        let data = {
            document
        }
        const query = await fetch(`${URL_ROUTE.LISTMODULETEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteModuleTeacher(id, status) {
        let data = {
            id, status
        }
        const query = await fetch(`${URL_ROUTE.DELETEMODULETEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async moduleTeacher(document, dataRow) {
        let data = {
            document, dataRow
        }
        const query = await fetch(`${URL_ROUTE.MODULETEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // Buscamos los estudiantes que estan matriculados y asiganos a un docente
    async listestudentsteacher(id_course, id_teacher) {
        let data = {
            id_course, id_teacher
        }
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTSTEACHER}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveevidencecourse(id_attendance, upload) {
        let data = new FormData()
        data.append('id_attendance', id_attendance)
        data.append('upload', upload)

        const query = await fetch(`${URL_ROUTE.SAVEEVIDENCECOURSE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: data,
        });
        return query
    }

    async searchevidence() {
        const query = await fetch(`${URL_ROUTE.LISTAGREEMENT}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        });
        return query
    }

    async searchSesion(idStudentRegistration) {
        let data = {
            idStudentRegistration
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSESSION}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatusevidence(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSEVIDENCE}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // REPORTS
    async listStudentHours(document) {
        let data = {
            document
        }
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTHOURS}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listStudentHoursid(id, id_module) {
        let data = {
            id, id_module
        }
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTHOURSID}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listStudentDate(date) {
        let data = {
            date
        }
        const query = await fetch(`${URL_ROUTE.LISTSTUDENTDATE}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    // ATTENDANT
    async searchattendant(id_attendant) {
        let data = {
            id_attendant
        }
        const query = await fetch(`${URL_ROUTE.SEARCHATTENDANT}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    //Notify WS
    async createNotifyAll(text) {
        let data = {
            text
        }
        const query = await fetch(`${URL_ROUTE.CREATENOTIFYALL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createNotifyModule(category, module, text) {
        let data = {
            category, module, text
        }
        const query = await fetch(`${URL_ROUTE.CREATENOTIFYMODULE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createNotifyIndividual(document, text) {
        let data = {
            document, text
        }
        const query = await fetch(`${URL_ROUTE.CREATENOTIFYINDIVIDUAL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async createNotifyPrivate(number, text) {
        let data = {
            number, text
        }
        const query = await fetch(`${URL_ROUTE.CREATENOTIFYPRIVATE}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listNotifyAll(type) {
        let data = {
            type
        }
        const query = await fetch(`${URL_ROUTE.LISTNOTIFYALL}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listNotifyWhastsappAttendant(id_attendant) {
        let data = {
            id_attendant
        }
        const query = await fetch(`${URL_ROUTE.LISTNOTIFYWHATSAPPATTENDANT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async viewIWillPay(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.VIEWIWILLPAY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async viewIWillPayFormat(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.VIEWIWILLPAYFORMAT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async viewRiskCenter(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.VIEWRISKCENTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async viewPayrollDiscount(id) {
        let data = { id }
        const query = await fetch(`${URL_ROUTE.VIEWPAYROLLDISCOUNT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
            body: JSON.stringify(data),
        });
        return query
    }

    async forWardIWillPay(idLoan, name, value, daterequest, email) {
        let data = { idLoan, name, value, daterequest, email }
        const query = await fetch(`${URL_ROUTE.FORWARDIWILLPAY}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async forWardCCR(idLoan, name, daterequest, email) {
        let data = { idLoan, name, daterequest, email }
        const query = await fetch(`${URL_ROUTE.FORWARDCCR}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

}

export default new Api();
import React from "react"
import { Navbar } from "reactstrap"
import classnames from "classnames"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import whastappOn from "../../../assets/img/icons/whastappOn.png"
import whastappOff from "../../../assets/img/icons/whastappOff.png"
import { BsInfoCircle } from "react-icons/bs";

const ThemeNavbar = props => {
  const user = JSON.parse(sessionStorage.getItem("user"))
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  return (
    <React.Fragment>
      {/* <div className="content-overlay" />
      <div className="header-navbar-shadow" /> */}
      <Navbar  style={{ background: 'linear-gradient(to right, #7797b8, #1f304a', borderRadius: "7px" }}
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content" >
            <div className="navbar-collapse" id="navbar-mobile"> {/*d-flex justify-content-end*/}
              {/* user.profile == 2 ? user.whatsapplog == true ? <img src={whastappOn} style={{maxWidth:'50px', width:'100%'}} /> : <img src={whastappOff} style={{maxWidth:'50px', width:'100%'}} /> : ''  */}
              <div className="bookmark-wrapper" style={{width:"100%"}}>
                {
                  user.profile === 2 ?
                    <a style={{position:"relative",top:"19px",color:"#d9b70d"}} href="https://salmoapp.com/media/docs/manual-admin.pdf" target="blank"><BsInfoCircle size={22}/></a> :
                  user.profile === 1 ?
                    <a style={{position:"relative",top:"19px",color:"#d9b70d"}} href="https://salmoapp.com/media/docs/manual-usuario.pdf" target="blank"><BsInfoCircle size={22}/></a> : ""
                }
                <NavbarBookmarks
                  sidebarVisibility={props.sidebarVisibility}
                  handleAppOverlay={props.handleAppOverlay}
                />
                <NavbarUser
                  handleAppOverlay={props.handleAppOverlay}
                  changeCurrentLang={props.changeCurrentLang}
                  userName="John Doe"
                  userImg={ userImg }
                />
              </div>
              {/* {props.horizontal ? (
                <div className="logo d-flex align-items-center">
                  <div className="brand-logo mr-50"></div>
                </div>
              ) : null} */}
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}


export default ThemeNavbar
